import { createReducer, on } from '@ngrx/store';
import { shippingAddressesActions } from '../actions/shipping-addresses.actions';
import { ShippingAddress } from '../models';

export interface ShippingAddressesState {
    loaded: boolean | null;
    currentAddress: ShippingAddress | null;
    addresses: ShippingAddress[] | null;
}

export const initialState: ShippingAddressesState = {
    loaded: null,
    currentAddress: null,
    addresses: null,
};

export const shippingAddressesReducer = createReducer(
    initialState,
    on(
        shippingAddressesActions.getAddresses,
        (state): ShippingAddressesState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        shippingAddressesActions.getAddressesSuccess,
        (state, payload): ShippingAddressesState => ({
            ...state,
            loaded: true,
            currentAddress: payload.current,
            addresses: payload.items,
        }),
    ),
    on(
        shippingAddressesActions.getAddressesError,
        (state): ShippingAddressesState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(shippingAddressesActions.setAddressSuccess, (state, payload): ShippingAddressesState => {
        const address = state.addresses?.find((address) => address.code === payload.code);

        return {
            ...state,
            currentAddress: address ?? state.currentAddress,
        };
    }),
    on(shippingAddressesActions.clear, (): ShippingAddressesState => ({ ...initialState })),
);
