import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShippingAddressesState } from '../reducers/shipping-addresses.reducer';
import { SHIPPING_ADDRESSES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ShippingAddressesState>(SHIPPING_ADDRESSES_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state: ShippingAddressesState) => state.loaded);
const selectAddresses = createSelector(selectState, (state: ShippingAddressesState) => state.addresses);
const selectCurrentAddress = createSelector(selectState, (state: ShippingAddressesState) => state.currentAddress);

export { selectLoaded, selectAddresses, selectCurrentAddress };
